@import "styles/constants.scss";

.DateInput {
    font-size: $xxlarge-font-size!important;
    text-align: center;
    color: $accent;
    border-bottom: 3px solid $primary;
}

.Slasher {
    font-size: $xxlarge-font-size;
    color: $primary;
}


.OptionBold {
    :global(span) {
        color: #7A7A7A;
    }
}