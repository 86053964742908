@import "styles/constants.scss";

.NotFound,
.NotAuthorized {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: $primary;
  overflow: hidden;
}

.Logo {
  width: 200px;
  margin-bottom: $input-height;
}

.Title {
  color: white;
  text-transform: uppercase;
  font-size: $xxlarge-font-size;
  width: 80%;
}
.Layout {
  height: 100vh;
  position: absolute;
  width: 100%;
  background-color: $white;
  cursor: "pointer";
  text-align: "center";
}
