.NirDataPageContainer {
    display: flex;
    flex-direction: column;
}

.NirDataPageLabeledInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.NirDataPageLabele{
    width: 480px;
    text-align:end;
    color:#27224F;
    font-weight:600;
    font-size:28px;
    align-self: center;
}

.NirDataPageGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 13px;
    grid-row-gap: 24px;
}

.title__block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .title__icon{
  width : 160px;
  margin-bottom: 2rem;
  }
  
  .title__icon img{
    width : 100%;
  }
  
.title__text{
    max-width: 1000px;
    color: #211452;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
  }