@import "hocs/Keyboard.module.scss";
@import "react-phone-number-input/style.css";
@import "react-simple-keyboard/build/css/index.css";
@import "styles/constants.scss";

body {
  color: $black;
}

.lobby-image {
  width: 300px;
}

.font-size-small {
  font-size: $default-font-size;
}
.font-size-xlarge {
  font-size: $xlarge-font-size;
}
.font-size-large {
  font-size: $large-font-size;
}
.iframe-document {
  width: 100%;
  min-width: 70vw;
  height: 50vh;
  background-image: url("/assets/loaders/gimmick-video.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px;
  border: white;
}
.information-card {
  background: $red;
  font-size: $large-font-size;
  color: white;
  padding: 20px;
  margin-top: 50px;
  text-align: center;
}
.password-reasons {
  margin-top: 10px;
  background: transparent;
  border: none;
  color: black;
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-size: $medium-font-size;
  font-weight: 600;
}

.password-reasons li.active {
  color: inherit;
}

.password-reasons li i {
  margin-left: 5px;
}

.password-reasons li.active i {
  display: none;
}

.password-reasons-invalid {
  color: $black;
}

.password-reasons-valid {
  color: $green;
}

.table-modal {
  color: $primary;
  
  img {
    margin-right: 50px;
    padding: 33px;
  }
  &.small{
    margin : auto;
    img {
      margin-right: 20px;
      padding: 10px;
    }
  }
}
.center {
  text-align: center;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.bold {
  color: $primary;
  font-size: $large-font-size;
  font-weight: 600;
}
.paragraph {
  color: $primary;
  font-size: $large-font-size;
}
.uppercase-first-letter {
  display:inline-block;
  &:first-letter {
    text-transform: uppercase;
  }
}
.link {
  text-transform: initial;
  text-decoration: underline;
  color : $primary-light;
  font-size: 24px;
}
.error {
  color : $error;
}

.i-card {
  padding : 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  background : #EFEFEF;
  color : $primary;
  width: fit-content;
  margin: auto;
  font-size: $medium-font-size;
  border-radius : 5px;
}
.small-link {
  color: $accent;
  text-decoration: underline;
  font-size: $large-font-size;
  display: block;
  margin-top: 40px;
  text-align: center;
  cursor: pointer;
  // pour éviter qu'il passe sous le footer
  z-index: 1000;
}
.more-information {
  color: $primary-light;
  cursor: pointer;
  font-weight: 800;
  font-size: $large-font-size;
}