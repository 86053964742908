@import "styles/constants.scss";

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $large-font-size;
  color: black;
}

.text {
  text-align: center;
  max-width: 850px;
}