@import "styles/constants.scss";

.DisplayDoctor {
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  text-align: center;
  height: 100%;
  * {
    flex: 2;
  }
  font-size: $large-font-size;
}

.Available {
  flex: 1;
  color: $success;
  font-weight: bold;
}
.Unavailable {
  flex: 1;
  color: #bdbdbd;
  font-weight: bold;
}

.WrappedName {
  width: 300px;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.WrappedAddress {
  color: #9089a8;
}

.Requestor {
  color: $primary-light;
  padding-left: 4px;
  padding-right: 4px;
}
