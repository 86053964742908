@import "../../styles/constants.scss";
.Login {
}

.AccentCard {
  background-color: $sand !important;
  border-color: $primary !important;
}

.twoFaForm{
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center
}
.twoFaForm > * {
  flex: 1;
}

.twoFaModalBtn{
height: 274px;
width: 329px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 3px;
background: #F2F2F2;
box-shadow: 0px 2px 9px -1px rgba(0, 0, 0, 0.17);
border-radius: 20px;
font-size: 40px;
color : #211452;
cursor: pointer;

}

.resendBtnImage{
  width: 30%;
}

.modalTitle{
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 80px;
  text-align: center;
  color: #211452;
}

.errorMessage{
  font-weight: 700;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: #D64949; 
}

.ResendInTime{
margin-top: 2rem;
font-weight: 400;
font-size: 35px;
line-height: 43px;
text-align: center;
color: #C5C1C1;
}