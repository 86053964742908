@import "styles/constants.scss";

.LayoutHome {
  cursor: pointer;
  margin-right: 650px;
  /* width: 61vw; */
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.TouchScreen {
  color: $primary;
  font-size: $touchscreen-font-size;
  line-height: calc($touchscreen-font-size + 20px);
  font-weight: bold;
  text-align: center;
  margin: 0;
  width: 1000px;
}

.TaglineInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TaglineText {
  color: $primary;
  font-size: $tagline-font-size;
  font-weight: normal;
}
.ConnectedAs {
  color: $primary;
  font-size: $large-font-size;
  font-weight: bold;
  border-bottom: 2px solid;
  height: 36px;
}

.PharmaAccount {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 15px;
  top: 20px;
  left: 50px;
}
.HomepageImage {
  position: absolute;
  width: 630px;
  right: 0;
  top: 10vh;
}

.Contact {
  font-size: $large-font-size;
  margin-top: 50px;
  text-align: center;
  span {
    font-size: $medium-font-size;
    b {
      color: $red;
    }
  }
}

.Versioning {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 25px;
  text-align: center;
  color: $primary;
  font-size: $default-font-size;
}

.BannerMessage {
  background-color: #9b4f86 !important;
  font-size: 24px !important;
  color: $primary;
  width: 520px !important;
  line-height: 32px !important;
}

.AccessibilityLink {
  position: absolute;
  left: 1550px;
  right: 0;
  bottom: 30px;
  color: $primary;
  font-size: $default-font-size;
  z-index: 1;
}

.AccessibilityLink a, .AccessibilityLink a:hover, .AccessibilityLink:active, .AccessibilityLink:visited  {
  color: $primary;
}