@import "styles/constants.scss";

.ProfileLink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
  height: 50px;
  color: $primary;
  padding: 0 15px;
  border-radius: 12px;
  font-size: 25px;
  border: 1px solid transparent;
  box-shadow: 0 2px 9px -1px rgb(0 0 0 / 20%);
}

.Nowrap {
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
