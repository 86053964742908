@import "styles/styles.scss";

.Medadom {
  color: $primary;
  position: relative;
  text-transform: uppercase;
  font-family: "Now";
  font-size: $large-font-size;
}

.BlueFrench,
.RedFrench {
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 30%;
}
.BlueFrench {
  left: 0;
  background-color: $primary;
}
.RedFrench {
  right: 0;
  background-color: #cf1e21;
}
