@import "styles/constants.scss";
.Card {
  color: $primary;
  border: 1px solid $grey;

  padding: 5px;
  border-radius: 10px;
  height: $input-height;
  background: $white;
  font-size: $xlarge-font-size;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card.Form {
  max-width: 500px;
}
