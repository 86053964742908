@import "styles/constants.scss";
.Modal {
}
.CGUContent {
  overflow-y: scroll;
  height: 55vh;
}


.EngineDiagnoticModal {
  border-radius: 20px;
}


.modalTitle {
  text-align: center;
  font-size: $xxlarge-font-size;
  font-weight: bold;
  color: $primary;
}

.EngineDiagnoticModalButtons {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}