@import "styles/constants.scss";
.Previous {
  position: absolute;
  top: 15px;
  left: 50px;
  padding: 10px;
  z-index: 100;
  font-size: $medium-font-size;
  font-weight: 100;
  color: $primary;
  text-transform: uppercase;
  cursor: pointer;
  background: $white;
  border: 1px solid $primary;
}

.Previous svg {
  margin-right: 5px;
}
.Danger {
  color: $white;
  background: $error;
  border-color: $error;
}
