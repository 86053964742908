@import "styles/constants.scss";
.Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Clock {
  justify-content: center;
}
