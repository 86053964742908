@import "styles/constants.scss";

.Scroller {
  height: max-content;
}
.Information {
  width: 60%;
  margin: auto;
  font-size: $xlarge-font-size;
  margin-bottom: -20px;
  text-align: center;
}


.infoLabel{
  width: 480px;
  text-align:end;
  color:#27224F;
  font-weight:600;
  font-size:28px;
  align-self: center;
}

.title__block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title__icon{
width : 15rem;
}

.title__icon img{
  width : 100%;
}

.title__text{
margin-top: -10px;
margin-bottom: 30px;
font-weight: bold;
font-size: 48px;
text-align: center;
color: #211452;
}

.modal_body{
display: block;
color: #211452;
font-size: 40px;
font-weight: 500;
line-height: normal;
width: 1150px;
margin: auto;
text-align: center; 
margin-top: -15px;
}

.Row{
  margin-bottom: 23px;
}