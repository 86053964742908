@import "styles/constants.scss";

.SuccessContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 45px;
    margin-left: 100px;
    font-size: $large-font-size;
}

.SuccessContainer img {
    margin-right: 60px;
}

.SuccessContainer p {
    line-height: 60px;
    margin-top: 35px;
    margin-right: 75px;
}


.FailedContainer {
    font-size: $large-font-size;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}

.FailedContainer p {
    line-height: 60px;
}

.LoaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}

.SpinContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: $xlarge-font-size;
}

.LoaderLabel {
    margin-top: 50px;
}