.twoFaModalBtn{
  height: 274px;
  width: 329px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  background: #F2F2F2;
  box-shadow: 0px 2px 9px -1px rgba(0, 0, 0, 0.17);
  border-radius: 20px;
  font-size: 40px;
  color : #211452;
  cursor: pointer;
}

.resendBtnImage{
  width: 30%;
}

.modalTitle{
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: #211452;
}