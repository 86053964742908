@import "styles/constants.scss";

.CheckboxAME {
  padding: 30px;
  background-color: #efefef;
  margin-inline: auto;
}

.ExemptedContent {
  font-size : $medium-font-size!important;
}
