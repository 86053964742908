@import "styles/constants.scss";

.Finale {
  text-align: center;
}

.secondeContainer {
  color: $error;
}
.Img {
  margin-bottom: 30px;
}

.LegalMention {
  display: flex;
  max-width: 400px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 750px;
  img {
    padding-right: 20px;
    width: 50px;
  }
}

.ArticleSix {
  color: $primary;
  font-size: $default-font-size;
}

.spaceButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
