@import "styles/constants.scss";
.Message {
}
.Success {
  color: $success;
}
.Error {
  color: $error;
}
.Info .Warning {
  color: $primary;
}
