@import "styles/constants.scss";

.DropDown {
  height: auto;
}
.PhoneInput {
  input {
    outline: none;
  }
}
.FlagContainer {
  width: 220px;
  overflow: hidden;
  font-size: $medium-font-size;
}
.ButtonFlag.ButtonSelector {
  border-radius: 0;
  height: $input-height;
  padding: 0;
  overflow: hidden;
  border: 0;
  width: 220px;
  border-bottom: 2px solid;
  justify-content: space-between;
  display: flex;
  color: $primary;
  font-weight: 700;
}
.ButtonFlag.ButtonSelected {
  border-radius: 0;
  height: $input-height;
  padding: 0;
  overflow: hidden;
  border: 0;
  border-bottom: 2px solid;
}

.Flag {
  width: 103px;
}
.PopoverFrame {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 50vh;
  overflow: scroll;
  gap: 50px;
}
.ButtonFlag {
  display: flex;
}
.Previous {
  display: flex;
  flex: wrap;
  font-size: 54px;
  color: $error;
}
