.Content {
    display: flex;
    gap: 29px;
    flex-direction: column;
}
.Checkbox {
    display: flex;
    flex-direction: row;
    gap : 15px;
}
.Checkboxes {
    padding: 20px;
}
.CGUContent {
    overflow-y: scroll;
    height: 45vh;
}