@import "styles/constants.scss";

.FatButton {
  height: 250px;
  width: 330px;
  position: relative;
  background: $white;
  display: flex;
  border-radius: 20px;
  font-weight: medium;
  font-size: $large-font-size;
  color: $primary;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid $white;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px 0px #00000040;
  padding: 20px;

}
.Small {
  height: 200px;
  width: 260px;
}
.Disabled {
  border-color: transparent ;
  box-shadow: none;
}

.XlargeFontSize {
  font-size: $large-font-size;
}
.Invisible {
  box-shadow: none;
}
.FatButton.Pictured * {
  flex-grow: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    white-space: break-spaces;
    text-overflow: ellipsis;
  }
}

.smallSize {
  font-size: $medium-font-size;
  // line-height: 30px;
}
.inline {
  display: inline;
}
.medSize {
  font-size: $large-font-size;
}
.FatButton img {
  width: 100px;
}

.FatButton.Primary {
  background: $primary;
  border: 2px solid $primary;
  color: $white;
}

.FatButton.Accent {
  background-color: $accent;
  border-color: $accent;
  color: $sand;
}
.FatButton.Ghost {
  border: none;
  box-shadow: none;
  color: #5A5A5A;
}

.FatButton.Clicked {
  border-color: $accent;
}

.FatButton.Clicked::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 25px;
  height: 25px;
  background: url("/assets/icons/checked_circle-green.svg") round;
  z-index: 1;
}
